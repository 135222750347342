
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Presentation page components
import ExampleCard from "pages/Presentation/components/ExampleCard";

// Data
import data from "pages/Presentation/sections/data/pagesData";

function Pages() {
  const renderData = data.map(({ image, name, route }) => (
    <Grid item xs={12} md={6} sx={{ mb: { xs: 3, lg: 0 } }} key={name}>
      <Link to={route}>
        <ExampleCard image={image} name={name} display="grid" minHeight="auto" />
      </Link>
    </Grid>
  ));

  return (
<MKBox component="section" py={6}>
  <Container>
    <Grid
      container
      item
      xs={12}
      lg={6}
      flexDirection="column"
      alignItems="center"
      sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
    >
      <MKBadge
        variant="contained"
        color="info"
        badgeContent="Consientete"
        container
        sx={{ mb: 2 }}
      />
      <MKTypography variant="h2" fontWeight="bold">
        ¡Tiempo de Spa!
      </MKTypography>
      <MKTypography variant="body1" color="text">
        Además de consentirte para que disfrutes de una experiencia sin igual, también te cuidamos con los más estrictos controles de higiene que incluyen sanitización de todas nuestras áreas.
      </MKTypography>
    </Grid>
  </Container>

  <Container sx={{ mt: { xs: 8, lg: 16 } }}>
    <Grid container spacing={3} justifyContent="space-between">
      <Grid item xs={12}>
        <Grid container spacing={3} direction={{ xs: 'column', lg: 'row' }} wrap="nowrap">
          {renderData}
        </Grid>
      </Grid>
    </Grid>
  </Container>
</MKBox>

  );
}

export default Pages;
