

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Counters from "pages/Presentation/sections/Counters";
import Information from "pages/Presentation/sections/Information";
import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
import Testimonials from "pages/Presentation/sections/Testimonials";
import Download from "pages/Presentation/sections/Download";
import Pages from "pages/Presentation/sections/Secc.js";
import Secc2 from "pages/Presentation/sections/Secc2.js";

// Presentation page components
import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-presentation_.png";

function Presentation() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://wa.me/525528575584?text=Hola%2C%20me%20gustaría%20agendar%20una%20cita",
          label: "Reservar Ahora",
          color: "primary",
        }}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: { xs: "center", sm: "top", md: "top", lg: "center" },
          minHeight: { xs: "50vh", sm: "60vh", md: "75vh", lg: "80vh" },
          display: "grid",
          placeItems: "center",
        }}
        
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                textAlign: "center",
                whiteSpace: "nowrap", // Prevents wrapping on large screens
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                  whiteSpace: "normal", // Allows wrapping on mobile
                },
                [breakpoints.up("lg")]: {
                  fontSize: size["4xl"], // Ensures a larger font size for desktop
                  whiteSpace: "nowrap", // Keeps text in one line on desktop
                },
                overflow: "visible", // Ensures no text gets cut off on large screens
              })}
            >
              Consientete · Relajate · Rejuvenece
            </MKTypography>

            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >

            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {/* <Counters />
        <Information />
        <DesignBlocks /> */}
        <div id="pages">
          <Pages />
        </div>
        <div id="Posts">
        < Secc2/>
        </div>
        {/* <DesignBlocks /> */}
        <div id="design-blocks">
{/*           <DesignBlocks /> */}
        </div>
        {/* <Information /> */}
        {/*         <Testimonials />
        <Download /> */}
        

      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <CenteredFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
