

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import SimpleMap from "pages/LandingPages/Author/sections/Maps";

// Images
import bgImage from "assets/images/miau.jpg";

function Contact() {
  return (
    <MKBox component="section" py={{ xs: 0, lg: 6 }}>
      <Container>
        <Grid container item>
          <MKBox
            width="100%"
            bgColor="white"
            borderRadius="xl"
            shadow="xl"
            mb={6}
            sx={{ overflow: "hidden" }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                lg={5}
                position="relative"
                px={0}
                sx={{
                  backgroundImage: ({
                    palette: { gradients },
                    functions: { rgba, linearGradient },
                  }) =>
                    `${linearGradient(
                      rgba(gradients.dark.main, 0.8),
                      rgba(gradients.dark.state, 0.8)
                    )}, url(${bgImage})`,
                  backgroundSize: "cover",
                }}
              >
                <MKBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                >
                  <MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my="auto">
                    <MKTypography variant="h3" color="white" mb={1}>
                      Informacion de Contacto
                    </MKTypography>
                    <MKBox display="flex" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-phone" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        (+52) 55 6699 8060
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-envelope" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        hola@spadulcecielo.com
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-map-marker-alt" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        C. Porvenir 5-3, San Francisco, 56600 Chalco de Díaz Covarrubias, Méx.
                      </MKTypography>
                    </MKBox>
                    <MKBox my={2}>
                      <hr style={{
                        border: "none",
                        height: "1px",
                        backgroundColor: "#ccc", // Color de la línea
                        margin: "0 auto",
                        width: "100%", // Ajusta el ancho de la línea
                      }} />
                    </MKBox>
                    <MKTypography variant="h4" color="white" mb={1}>
                      Síguenos en redes sociales
                    </MKTypography>
                    <MKBox mt={3}>
                      <a href="https://www.facebook.com/Erendiracosmetologa" target="_blank" rel="noopener noreferrer">
                        <MKButton variant="text" color="white" size="large" iconOnly>
                          <i className="fab fa-facebook" style={{ fontSize: "1.25rem" }} />
                        </MKButton>
                      </a>

                      <a href="https://www.instagram.com/spadulcecielo" target="_blank" rel="noopener noreferrer">
                        <MKButton variant="text" color="white" size="large" iconOnly>
                          <i className="fab fa-instagram" style={{ fontSize: "1.25rem" }} />
                        </MKButton>
                      </a>


                      <a href="https://www.tiktok.com/@spadulcecielo" target="_blank" rel="noopener noreferrer">
                        <MKButton variant="text" color="white" size="large" iconOnly>
                          <i className="fab fa-tiktok" style={{ fontSize: "1.25rem" }} />
                        </MKButton>
                      </a>

                    </MKBox>
                  </MKBox>
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={7}>
                <MKBox component="form" p={2} method="post">
                  <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                    <MKTypography variant="h2" mb={1}>
                      ¡Visítanos y disfruta de una experiencia única!
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mb={2}>
                      Te esperamos en nuestra ubicación para ofrecerte el mejor cuidado y relajación.


                    </MKTypography>
                    <SimpleMap />
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Contact;
